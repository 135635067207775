@import "_generated-font-mixins.scss";
.social {
  min-height: 20px;
}

.bargaining {
  position: relative;

  flex: 0 0 1%;
  flex-grow: 1;

  max-width: 50%;
  margin-top: 30px;
  padding: 20px 92px 20px 20px;

  background-color: rgb(115 178 34 / 10%);
  border-radius: 4px;

  &__text {
    height: 34px;
    font-size: 15px;
    line-height: 17px;
    color: #333;
  }

  &__newFlag {
    position: absolute;
    top: -6px;
    right: -6px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1px 5px;

    font-size: 10px;
    color: #fff;
    letter-spacing: 1px;

    background-color: #fcba2b;
    border-radius: 4px;
  }

  & button {
    width: 187px;
    height: 28px;
    margin-top: 20px;
    font-size: 14px;
  }
}

.bargaining::after {
  @include icon-bargaining;

  position: absolute;
  top: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 52px;

  color: #73b222;

  background-color: #fff;
  border-radius: 50%;
}

.ad {
  display: flex;
  display: table;
  flex-direction: row;
  justify-content: space-between;

  width: 1000px;

  &__container {
    position: relative;
    width: 1000px;
    margin: 0 auto;
  }

  &__content {
    @media print {
      width: 1000px;
    }

    float: left;

    width: 750px;
    margin-right: 10px;
    margin-left: -1px;
    padding-left: 1px;
  }

  &__recommended {
    position: relative;
    overflow: hidden;
    margin-top: 36px;
    padding-top: 12px;

    &__same {
      margin-top: 36px;

      & div:first-child {
        display: flex;
        justify-content: space-between;

        & h2 {
          margin: 0;
        }
      }

      & a {
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        color: #0058c5;
      }
    }
  }
}

.title {
  position: relative;

  margin-right: 8px;
  margin-bottom: 30px;

  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #333;
}

.views {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & span {
    margin-left: 4px;
  }
}

.link {
  position: absolute;
  top: 13px;
  right: 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  color: #0058c5;
}

.map__wrapper {
  margin-top: 30px;
}

.same__user {
  margin-top: -30px;
}
